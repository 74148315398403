<template>
  <div class="article">
    <button
      v-if="hasFavouriteButton"
      aria-label="Add article to favourites"
      :data-testid="`${dataTestid}-favourite`"
      class="article__favourite"
      type="button"
      @click="onFavoriteClick"
    >
      <reading-room-icon
        :type="favouriteIcon"
      />
    </button>

    <component
      v-if="isArticle"
      :is="articleComponent"
      v-bind="{
        article,
        content,
        id,
        isCompleted,
        isJourney,
        isReadingRoom,
      }"
      @duration="onDuration"
      @submit="onSubmit"
    />

    <item-component
      v-else
      is-reading-room
      :testid="dataTestid"
      v-bind="{ item }"
    />
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'

  import EmptyView from '@/components/empty-view/EmptyView'
  import ReadingRoomIcon from '@/components/reading-room/ReadingRoomIcon'
  import SimpleTile from '@/components/tiles/simple-tile'

  import { ARTICLE_TYPES } from '@/constants'

  import forceAnnounce from '@/mixins/forceAnnounce'
  import i18n from '@/mixins/i18n'
  import testid from '@/mixins/testid/testid'

  import ItemComponent from './ItemComponent'

  const importArticle = file => () => import(`./${file}`)

  export default {
    components: {
      BaseArticleProfileData: importArticle('BaseArticleProfileData'),
      BaseArticleText: importArticle('BaseArticleText'),
      BaseArticleSurvey: importArticle('BaseArticleSurvey'),
      BaseArticleVideo: importArticle('BaseArticleVideo'),
      EmptyView,
      ItemComponent,
      ReadingRoomIcon,
      SimpleTile,
    },

    mixins: [
      i18n,
      forceAnnounce,
      testid(),
    ],

    props: {
      allowFavourite: {
        type: Boolean,
        default: false,
      },
      article: {
        type: Object,
        default: null,
      },
      content: {
        type: Object,
        default: null,
      },
      id: {
        type: [String, Number],
        required: true,
      },
      isArticle: {
        type: Boolean,
        default: true,
      },
      isCompleted: {
        type: Boolean,
        default: false,
      },
      isFavourite: {
        type: Boolean,
        default: false,
      },
      isJourney: {
        type: Boolean,
        required: false,
        default: false,
      },
      isReadingRoom: {
        type: Boolean,
        required: false,
        default: false,
      },
      item: {
        type: Object,
        default: () => ({}),
      },
    },

    destroyed() {
      this.resetBreadcrumbs()
    },

    watch: {
      article: {
        handler() {
          if (this.article) {
            const { display_name: name } = this.article
            this.setBreadcrumbTitle(this.article.title || name)
          }
        },
        immediate: true,
      },
    },

    methods: {
      ...mapMutations('route', [
        'setBreadcrumbTitle',
        'resetBreadcrumbs',
      ]),
      onFavoriteClick() {
        this.$emit('clickFavourite')
        this.forceAnnounce(this.isFavourite
          ? this.i18n('favorites-remove')
          : this.i18n('favorites-add'))
      },
      onDuration(seconds) {
        this.$emit('duration', seconds)
      },
      onSubmit() {
        this.$emit('submit')
      },
    },

    computed: {
      articleComponent() {
        return ARTICLE_TYPES[this.article?.type] ?? null
      },
      hasFavouriteButton() {
        return (this.article || this.item) && this.allowFavourite
      },
      favouriteIcon() {
        return this.isFavourite ? 'favourite' : 'favourite-unchecked'
      },
    },

    slug: 'component.base.base-article',
  }
</script>

<style lang="scss">
  .article {
    width: 100%;
    position: relative;
    padding: 0 0 4rem;
    &__empty {
      margin-bottom: 0;
      .simple-tile {
        &__main {
          padding: 0;
        }
        &__content {
          margin-bottom: 0;
        }
      }
    }
    &__favourite {
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: z-index(over-base) + 5;
      @include min-lg {
        top: 2rem;
        right: 3rem;
      }
    }
    .article-text__content,
    .article-video__content,
    .article-profile-data__content legend {
      a {
        color: color(dark-primary);
        font-weight: 600;
        text-decoration: underline;
        &[href^='#'] {
          color: color(secondary-text);
          text-decoration: none;
        }
      }
      blockquote {
        border-left: 8px solid color(primary);
        padding: 12px;
        margin-left: 0;
        margin-bottom: 26px;
        p {
          margin: 1rem 0;
        }
      }
      a,
      b,
      strong,
      i,
      em,
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: $averta !important;
      }
      p {
        margin: 1rem 0;
        font-size: 1.6rem !important;
        @include min-lg {
          font-size: 1.9rem !important;
        }
      }
      ul,
      ol {
        font-size: 1.6rem !important;
        @include min-lg {
          font-size: 1.9rem !important;
        }
      }
      h1 {
        margin: 1rem 0;
        font-size: 4.8rem;
        font-weight: 400;
      }
      h2 {
        font-size: 3rem;
        font-weight: 400;
      }
      h3 {
        font-size: 2.2rem;
        font-weight: 800;
      }
      h4 {
        font-size: 1.8rem;
        font-weight: 600;
      }
      h5 {
        font-size: 1.4rem;
        font-weight: 800;
        text-transform: uppercase;
      }
      h6 {
        font-size: 1.4rem;
        font-weight: 600;
      }
      h2,
      h3 {
        margin: 1rem 0;
        color: color(primary-text);
      }
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        &:not(.modular-tile__top-image) {
          margin: 1rem 0;
          border-radius: 1rem;
        }
      }
      ol {
        margin: 1rem 0;
        counter-reset: list;
        & > li {
          list-style: none;
          &::before {
            content: counter(list) ') ';
            counter-increment: list;
          }
        }
      }
      ul {
        margin: 1rem 0;
        & > li {
          padding-left: 1.6rem;
          &::before {
            content: '•';
          }
        }
      }
      svg:not(.spinner) {
        height: 1rem;
      }
      .ql-align-center {
        text-align: center;
      }
      .ql-align-right {
        text-align: right;
      }
      table {
        width: 100%;
        margin: 2rem 0;
        text-align: center;
        border-collapse: collapse;
        border: none;
        colgroup col {
          width: 100%;
        }
        @include min-lg {
          colgroup col {
            width: inherit;
          }
        }
        tr {
          border-bottom: 1px solid color(light-grey);
          &:first-child {
            display: none;
          }
          @include min-lg {
            &:first-child {
              display: table-row;
            }
          }
        }
        td, th {
          height: fit-content !important;
          display: flex;
          justify-content: space-between;
          padding: 5px;
          border: none;
          border-bottom: 1px solid color(light-grey);
          font-size: 1.6rem !important;

          &::before {
            content: attr(attr-header);
            display: block;
            // text-align:center;
            font-weight: bold;
            width: 50% !important;
            margin-right: 0.5rem;
          }
          p {
            margin: 0;
            width: 50%;
          }

          @include min-lg {
            display: table-cell;
            font-size: 1.9rem !important;
            &::before {
              content: none
            }
            p {
            width: inherit;
          }
          }
        }
      }
    }
    .modular-tile {
      &__header {
        font-size: 3rem;
        font-weight: 400;
        text-transform: none;
      }
    }
  }
</style>
