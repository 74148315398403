<template>
  <div
    class="goals-form-step"
    data-testid="goals-form-step"
  >
    <p
      v-if="description"
      :id="`goals-form-step-description-${uuid}`"
      class="goals-form-step__description"
      data-testid="goals-form-step-description"
    >
      {{ description }}
    </p>
    <p
      v-if="extraDescription"
      class="goals-form-step__extra-description"
    >
      {{ extraDescription }}
    </p>

    <div :class="showWeight && isStonesAndPounds ? 'display-fields-inline' : ''">
      <goals-form-field
        v-for="(actionField, index) in actionFields"
        :key="actionField.name"
        data-testid="goals-form-step-field"
        :field="actionField"
        :isAdditional="index > 0"
        :model="answers"
        :showWeight="showWeight"
        @remove-field="removeField(index)"
      />
    </div>

    <base-button
      v-if="allowMultiple"
      class="goals-form-step__add-answer"
      data-testid="goals-form-step-add-answer"
      @click="addAnswerField"
    >
      {{ $t(`${$options.slug}.add-answer`) }}
    </base-button>

    <code-slot
      v-if="chMessage && isTrackingGoal"
      ref="codeSlot"
      class="goals-form-step__message"
      data-testid="goals-form-step-message"
      :content="chMessage"
    />

    <speech-bubble
      v-else-if="chMessage"
      data-testid="goals-form-step-speech-bubble"
      :imageLink="imageUrl"
    >
      {{ chMessage }}
    </speech-bubble>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { BaseButton } from '@/components/base'
  import CodeSlot from '@/components/code-slot/CodeSlot'
  import GoalsFormField from '@/components/goals/GoalsFormField'
  import SpeechBubble from '@/components/speech-bubble/SpeechBubble'

  import forceAnnounce from '@/mixins/forceAnnounce'

  export default {
    inheritAttrs: false,

    components: {
      BaseButton,
      CodeSlot,
      GoalsFormField,
      SpeechBubble,
    },

    mixins: [
      forceAnnounce,
    ],

    props: {
      actionFields: {
        type: Array,
        required: true,
      },
      allowMultiple: {
        type: Boolean,
        default: false,
      },
      answers: {
        type: Object,
        required: true,
      },
      chMessage: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      extraDescription: {
        type: String,
        default: '',
      },
      showWeight: {
        type: Boolean,
        default: false,
      },
      imageUrl: {
        type: String,
        default: null,
      },
      isTrackingGoal: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      removeField(index) {
        this.$emit('remove-field', index)
        this.forceAnnounce(this.$t('Field removed'))
      },
      addAnswerField() {
        this.$emit('add-answer')
        this.forceAnnounce(this.$t('Field added'))
      },
    },

    computed: {
      ...mapGetters('user', [
        'isStonesAndPounds',
        'getCurrentUserWeightUnit',
      ]),
    },

    slug: 'component.goals.goals-form-step',
  }
</script>

<style lang="scss">
  .goals-form-step {
    display: flex;
    flex-direction: column;
    &__description {
      font-size: $font-size-p-lg;
      font-weight: 600;
      margin-bottom: 0.5rem;
      // @include min-lg {
      //   font-size: $font-size-p-lg;
      // }
    }
    &__extra-description {
      font-size: $font-size-p-sm;
    }
    &__add-answer {
      margin: 1rem 0 1rem auto;
    }
    &__message {
      margin: 2rem 0;
      font-weight: 400;
    }
    .display-fields-inline {
      display: flex;
      .goals-form-field {
        width: 100%;
        .input-group {
          margin: 0;
        }
        &__weight {
          margin: 0;
          margin-left: 1rem;
          margin-right: 2rem;
          margin-top: 2rem;
        }

      }
    }
  }
</style>
