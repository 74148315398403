<template>
    <figure
      :class="{
        'video-article-embed': true,
        'video-article-embed__border': addBorder
      }"
      :aria-labelledby="figureId"
    >
      <div class="video-article-embed__inner">
        <video-player
          v-if="item"
          ref="player"
          v-bind="{ item, captions, alternateCaptions }"
          @player="setPlayer"
        />
        <div
          v-else
          class="video-article-embed__placeholder"
        >
          <spinner />
        </div>

        <figcaption
          v-if="title && addTitle"
          class="video-article-embed__caption"
          :id="figureId"
          >
          <p>{{ $t('Video') }}: {{ title }}</p>
        </figcaption>
      </div>
    </figure>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import Spinner from '@/components/spinner/Spinner'
  import VideoPlayer from '@/components/video-player/VideoPlayer'

  export default {
    name: 'VideoArticle',

    components: {
      VideoPlayer,
      Spinner,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
      tags: {
        type: String,
        required: false,
        default: '',
      },
    },

    watch: {
      id: {
        handler() {
          this.fetchVideoArticle(parseInt(this.id, 10))
        },
        immediate: true,
      },
      tags: {
        handler() {
          if (!this.tags || this.tags === 'undefined') {
            return
          }

          this.addBorder = this.tags.includes('border')
          this.addTitle = this.tags.includes('title')
        },
        immediate: true,
      },
      item: {
        handler(item) {
          if (!item) return

          this.captions = null

          this.fetchVideoCaptions({
            id: this.id,
            language: item.language,
          })
          .then(captionsUrl => this.captions = captionsUrl)

          this.fetchVideoCaptions({
            id: this.id,
            language: item.language,
            isAlternateCaptions: true,
          })
          .then(captionsUrl => this.alternateCaptions = captionsUrl)
        },
        immediate: true,
      },
    },

    data() {
      return {
        captions: '',
        alternateCaptions: '',
        player: null,
        addBorder: true,
        addTitle: true,
      }
    },

    methods: {
      ...mapActions('article', [
        'fetchVideoArticle',
        'fetchVideoCaptions',
      ]),
      setPlayer(player) {
        this.player = player

        if (player) this.passDuration()
      },
      passDuration() {
        if (this.player.readyState() < 1) {
          this.player.one('loadedmetadata', () => this.emitDuration(this.player?.duration))
        } else {
          this.emitDuration(this.player?.duration)
        }
      },
      emitDuration(duration) {
        this.$emit('duration', duration)
      },
    },

    computed: {
      ...mapGetters('article', [
        'getVideoArticleById',
      ]),
      item() {
        return this.getVideoArticleById(this.id)
      },
      title() {
        return this.item?.title
      },
      figureId() {
        return `video-article-embed_${this.uuid}`
      },
    },
  }
</script>

<style lang="scss">
.video-article-embed {
  margin: 1rem 0;

  &__border {
    border: 1px solid;
    .video-article-embed__inner {
      padding: 1rem;
    }
  }
  border-radius: 1rem;
  &__placeholder {
    background: color(_black);
    position: relative;
    &::before {
      content: '';
      display: block;
      padding-top: (9 / 16 * 100%);
    }
    .spinner {
      $_size: 3.2rem;
      width: $_size;
      height: $_size;
      margin-top: -0.5 * $_size;
      margin-left: -0.5 * $_size;
      position: absolute;
      top: 50%;
      left: 50%;
      &-path {
        stroke: color(_white);
      }
    }
  }
  .video-player {
    .video-js {
      width: 100%;
      height: auto;
      position: relative;
      &::before {
        content: '';
        display: block;
        padding-top: (9 / 16 * 100%);
      }
      video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
