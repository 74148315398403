<template>
  <modular-tile
    class="item-component"
    :headerText="item.title"
  >
    <span class="item-component__added-date">
      {{ $t(`${$options.slug}.added`) }}
      <strong>{{ item.created_at | formatDate(DATE_FORMATS.date) }}</strong>
    </span>

    <p>
      {{ item.description }}
    </p>

    <base-button
      class="item-component__view"
      :link="viewButtonLink"
      target="_blank"
    >
      {{ $t(`${$options.slug}.view`) }}
    </base-button>

    <reading-room-navigation
      v-if="isReadingRoom && categories"
      :ariaLabel="$t('Content')"
      :categoryId="$route.params.categoryId"
      :id="item.id || $route.params.id"
      :testid="dataTestid"
    />
  </modular-tile>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import { ReadingRoomNavigation } from '@/components/reading-room'
  import ModularTile from '@/components/tiles/modular-tile'

  import { DATE_FORMATS } from '@/constants'

  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      BaseButton,
      ModularTile,
      ReadingRoomNavigation,
    },

    mixins: [
      testid(),
    ],

    props: {
      item: {
        type: Object,
        required: true,
      },
      isReadingRoom: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        DATE_FORMATS,
      }
    },

    computed: {
      ...mapGetters('triggers', [
        'getTriggerRouteByPluginId',
      ]),
      ...mapState('readingRoom', [
        'categories',
      ]),
      isPlugin() {
        return this.item?.type === 'plugin'
      },
      viewButtonLink() {
        return this.isPlugin
          ? { name: this.getTriggerRouteByPluginId(this.item.value) }
          : this.item?.value
      },
    },

    slug: 'component.base.base-article.item-component',
  }
</script>

<style lang="scss">
  .item-component {
    font-size: 1.2rem;
    @include min-lg {
      font-size: 1.4rem;
    }
    &__added-date {
      margin-bottom: 1rem;
      color: color(grey);
    }
    &__view {
      margin-top: 1.5rem;
      align-self: center;
    }
    .modular-tile {
      &__header-row {
        margin: 0.4rem 0 0.8rem;
        padding-right: 3rem;
        @include min-lg {
          margin: 1rem 0 0.6rem;
        }
      }
      &__header {
        font-size: 1.6rem;
        font-weight: 800;
        text-transform: none;
        @include min-lg {
          font-size: 2.2rem;
        }
      }
      &__content {
        flex-direction: column;
      }
    }
  }
</style>
