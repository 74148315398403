<template>
  <div
    class="goals-form-field"
    data-testid="goals-form-field"
  >
    <form-fields-generator-field
      class="goals-form-field__fieldset"
      data-testid="goals-form-field-field"
      v-bind="{ field, dataModel: model }"
    />

    <span
      v-if="showWeight"
      class="goals-form-field__weight"
      data-testid="goals-form-field-weight"
    >
      {{ unit }}
    </span>

    <button
      v-if="showRemoveButton"
      class="goals-form-field__remove-field"
      data-testid="goals-form-field-remove-field"
      type="button"
      :title="$t(`Delete answer ${name}`)"
      @click="onRemoveField"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import FormFieldsGeneratorField from '@/components/forms/form-fields-generator/FormFieldsGeneratorField'

  export default {
    components: {
      FormFieldsGeneratorField,
    },

    props: {
      field: {
        type: Object,
        required: true,
      },
      isAdditional: {
        type: Boolean,
        default: false,
      },
      model: {
        type: Object,
        required: true,
      },
      showWeight: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      onRemoveField() {
        this.$emit('remove-field')
      },
    },

    computed: {
      ...mapGetters('user', {
        getCurrentUserId: 'getCurrentUserId',
        currentUserWeightUnit: 'getCurrentUserWeightUnit',
        isStonesAndPounds: 'isStonesAndPounds',
      }),
      name() {
        return this.model?.[this.field?.name]
      },
      showRemoveButton() {
        if (this.field.props.hiddenRemoveField) {
          return !this.field.props.hiddenRemoveField
        }
        return this.isAdditional
      },
      unit() {
        if (!this.isStonesAndPounds) {
          return this.currentUserWeightUnit.abbr
        }
        const [, , fieldIndex] = this.field.name.split('_')
        return this.currentUserWeightUnit.abbr[fieldIndex]
      },
    },
  }
</script>

<style lang="scss">
  .goals-form-field {
    display: flex;
    margin-top: 1.5rem;
    .input-group__container,
    .textarea-group__container {
      font-size: 1.2rem;
      @include min-lg {
        font-size: 1.4rem;
      }
    }
    .input-group,
    .textarea-group {
      margin: 0;
    }
    .textarea-group {
      padding: 0;
    }
    &__fieldset {
      flex: 1;
    }
    &__weight {
      align-self: center;
      margin-left: 1rem;
    }
    &__remove-field {
      align-self: flex-start;
      width: 2rem;
      height: 2rem;
      margin-left: 1rem;
      padding: 0;
      position: relative;
      &::before,
      &::after {
        content: '';
        display: block;
        width: 2rem;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        background-color: color(error);
      }
      &::after {
        transform: translateY(-50%) rotate(45deg);
      }
      &::before {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
</style>
