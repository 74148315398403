var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',_vm._b({attrs:{"tag":'div'}},'form-group',{
    descriptionId: _vm.descriptionId,
    mode: _vm.mode,
    name: _vm.name,
    testid: _vm.testid,
    validation: _vm.validation,
  },false),[_c('calendar-picker',_vm._b({staticClass:"calendar-picker-group",attrs:{"has-form":"","aria-describedby":_vm.descriptionId},on:{"monthChanged":function (date) { return _vm.$emit('monthChanged', date); }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'calendar-picker',{
      buttonNowTranslation: _vm.buttonNowTranslation,
      disabled: _vm.disabled,
      disabledDates: _vm.disabledDates,
      disabledHours: _vm.disabledHours,
      disabledWeekly: _vm.disabledWeekly,
      firstDayOfWeek: _vm.firstDayOfWeek,
      format: _vm.format,
      formatted: _vm.formatted,
      hasHiddenAsterisk: _vm.hasHiddenAsterisk,
      inline: _vm.inline,
      label: _vm.label,
      maxDate: _vm.maxDate,
      minDate: _vm.minDate,
      minuteInterval: _vm.minuteInterval,
      name: _vm.name,
      noButtonNow: _vm.noButtonNow,
      noWeekendsDays: _vm.noWeekendsDays,
      outputFormat: _vm.outputFormat,
      overlay: _vm.overlay,
      range: _vm.range,
      testid: _vm.testid,
      validation: _vm.validation,
      availableDates: _vm.availableDates,
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }