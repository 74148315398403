<template>
  <article
    class="article-text"
    data-testid="base-article-text"
  >
    <modular-tile
      :headerText="isSectionSummary ? 'Section complete' : article.title"
      :imagePosition="'top'"
      :headerLevel="1"
      v-bind="{ image, isSectionSummary, progress: currentProgress, program: program.name }"
    >

      <div v-if="getContent">
        <base-article-recipe-summary
          v-if="isRecipeSummary"
          :recipe="recipe" />

        <code-slot
          class="article-text__content"
          ref="codeSlot"
          data-testid="base-article-text-content"
          :content="getContent"
          @ready="override"
        />
      </div>

      <p
      class="nhsuk-body-m nhsuk-u-secondary-text-color nhsuk-u-margin-top-7 nhsuk-u-margin-bottom-0"
      >
        {{ $t('Page last reviewed') }}: {{ lastReviewed }}<br>
        {{ $t('Next review due') }}: {{ nextReview }}
      </p>
      <journey-navigation
        v-if="isJourney"
        :testid="'base-article-text'"
        :key="isLoading"
        :articleId="$route.params.id"
        :canProceed="!isLoading"
        v-bind="{
          isLoading,
        }"
      />
      <reading-room-navigation
        v-else-if="isReadingRoom"
        :ariaLabel="$t('Content')"
        :categoryId="$route.params.categoryId"
        :id="$route.params.id"
        v-bind="{
          isLoading,
        }"
      />
    </modular-tile>
  </article>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapGetters, mapState } from 'vuex'

  import CodeSlot from '@/components/code-slot/CodeSlot'
  import { JourneyNavigation } from '@/components/journey'
  import { ReadingRoomNavigation } from '@/components/reading-room'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  import requireImage from '@/mixins/requireImage'

  import BaseArticleRecipeSummary from './BaseArticleRecipeSummary'

  const SPACE_KEYCODE = 32
  const ENTER_KEYCODE = 13
  const MONTHS_NEXT_REVIEW = 24

  export default {
    inheritAttrs: false,

    components: {
      JourneyNavigation,
      CodeSlot,
      ModularTile,
      ReadingRoomNavigation,
      BaseArticleRecipeSummary,
    },

    mixins: [
      requireImage,
    ],

    props: {
      article: {
        type: Object,
        required: true,
      },
      content: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      isJourney: {
        type: Boolean,
        default: false,
      },
      isReadingRoom: {
        type: Boolean,
        default: false,
      },
    },

    created() {
      const section = this.sectionOfArticle
      if (section?.items && section.items.length > 0) {
        const lastArticleOfSection = section.items[section.items.length - 1]
        if (lastArticleOfSection?.id === this.article.id && this.article.title === 'Section complete') {
          this.isSectionSummary = true
          this.currentProgress = this.progress.progress
        }
      }
    },

    mounted() {
      this.setLastReviewed()
      this.initializeSpoilers()
      this.$emit('duration', null)
    },

    updated() {
      if (!this.content) return
      this.initializeSpoilers()
    },

    beforeDestroy() {
      this.toggleEventListeners('remove')
    },

    watch: {
      content: {
        handler() {
          this.setLastReviewed()
        },
        immediate: true,
      },
      article: {
        handler() {
          this.fetchQuestions(this.article.id)
        },
        immediate: true,
      },
    },

    data() {
      return {
        lastReviewed: '',
        nextReview: '',
        isSectionSummary: false,
        currentProgress: null,
      }
    },

    methods: {
      ...mapActions('quickQuestions', [
        'fetchQuestions',
      ]),
      initializeSpoilers() {
        const setAttributes = ({ button, content, i, spoiler, addArrow }) => {
          if (addArrow) {
            const arrow = document.createElement('div')

            arrow.classList.add('spoiler-arrow')
            spoiler.appendChild(arrow)
          }

          /* eslint-disable no-unused-expressions */
          button?.setAttribute('aria-controls', `spoiler_${i}`)
          button?.setAttribute('id', `spoiler-button_${i}`)

          content?.setAttribute('id', `spoiler_${i}`)
          content?.setAttribute('aria-labelledby', `spoiler-button_${i}`)
          /* eslint-enable no-unused-expressions */
        }
        this.iterateOverSpoilers(setAttributes)
        this.toggleEventListeners('add')
      },
      toggleEventListeners(action) {
        const events = ['click', 'keydown']
        const toggle = ({ arrow, button }) => {
          [arrow, button].forEach(item => {
            events.forEach(event => {
              if (item && (item.type !== 'button' || event == 'click')) {
                item[`${action}EventListener`](event, this.toggleSpoiler)
              }
            })
          })
        }

        this.iterateOverSpoilers(toggle)
      },
      iterateOverSpoilers(fn) {
        const spoilers = this.$el.querySelectorAll('.spoiler')

        spoilers.forEach((spoiler, i) => {
          const arrow = spoiler.querySelector('.spoiler-arrow')
          const content = spoiler.querySelector('.spoiler-content')
          const heading = spoiler.querySelector('.spoiler-heading')
          let button = spoiler.querySelector('button')
          const addArrow = !!button

          if (!button && heading) {
            button = document.createElement('button')

            button.innerText = heading.innerText
            button.setAttribute('type', 'button')
            heading.innerText = ''
            heading.appendChild(button)
          }

          fn({ arrow, button, content, i, spoiler, addArrow })
        })
      },
      override() {
        this.overrideElements(this.$refs.codeSlot.$children[0].$el)
      },
      overrideElements({ children }) {
        const overrideFunctions = {
          A: this.overrideA,
          IMG: this.overrideImg,
        }

        Array.from(children).forEach(child => {
          overrideFunctions[child.nodeName]
            ? overrideFunctions[child.nodeName](child)
            : this.overrideElements(child)
        })
      },
      overrideA(element) {
        element.onclick = e => {
          e.preventDefault()

          const cmsURLDestination = e.target.target === '_blank'
            ? 'External'
            : 'Internal'

          this.$mam.event('CMS Content URLs', {
            cms_url_destination: e.target.href,
            cms_url_type: cmsURLDestination,
            cms_object_item_id: this.article.id,
            cms_object_system_name: this.article.title,
          })

          if (cmsURLDestination === 'External') {
            Object.assign(
              document.createElement('a'),
              { target: '_blank', href: e.target.href },
            ).click()
          }
        }
      },
      overrideImg(element) {
        if (!element.alt) {
          element.setAttribute('alt', '')
        }
      },
      async toggleSpoiler(e) {
        const { keyCode, target, type } = e

        if (type == 'keydown' && (keyCode != ENTER_KEYCODE && keyCode != SPACE_KEYCODE)) return

        const spoiler = target.closest('.spoiler')
        const arrow = spoiler.querySelector('.spoiler-arrow')
        const button = spoiler.querySelector('button')
        const isExpanded = arrow.getAttribute('aria-expanded') === 'true'
        const clickable = [arrow, button]

        spoiler.classList.toggle('spoiler--is-open')

        await this.$nextTick()

        clickable.forEach(el => el.setAttribute('aria-expanded', !isExpanded))
      },
      setLastReviewed() {
        let updatedAt
        if (this.article && this.article.updated_at) {
          updatedAt = this.article.updated_at
        } else if (this.content && this.content.updated_at) {
          updatedAt = this.content.updated_at
        }
        if (updatedAt) {
          this.lastReviewed = moment(updatedAt).format('DD MMMM YYYY')
          this.nextReview = moment(updatedAt).add(MONTHS_NEXT_REVIEW, 'months').format('DD MMMM YYYY')
        }
      },
    },

    computed: {
      ...mapState('program', [
        'program',
        'progress',
      ]),
      ...mapState('layout', [
        'os',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      image() {
        return this.article?.hero?.url
      },
      isRecipeSummary() {
        return this.recipe.show
      },
      getContent() {
        return this.content?.content || this.article?.content
      },
      recipe() {
        return {
          show: this.article.recipe_show,
          dietSuitability: this.article.recipe_diet_suitability,
          allergenInformation: this.article.recipe_allergens,
          minutesReady: this.article.recipe_ready_in,
          minutesPrep: this.article.recipe_prep_time,
          minutesCooking: this.article.recipe_cook_time,
          peopleServes: this.article.recipe_servings,
        }
      },
      isLoading() {
        return this.getLoadingStatesForActions([
          'article/fetchArticle',
          'program/fetchNextSection',
          'readingRoom/fetchItem',
          'quickQuestions/fetchQuestions',
        ])
      },
      sectionOfArticle() {
        return this.program?.journey?.sections?.find(section => (
          section.items?.find(item => item.id == this.article.id)
        ))
      },
    },
  }
</script>

<style lang="scss">
  .article-text {
    &__content {
      font-family: $averta !important;
      font-size: 1.4rem !important;
      line-height: 1.5;
      word-break: break-word;
      overflow: visible;
    }
    .grid {
      width: 100%;
    }
    &-link-icon {
      height: 1rem;
      margin-right: 0rem;
    }
    .spoiler {
      margin: 2.5rem 0;
      padding: 2rem 3.3rem 2.5rem 4.7rem;
      position: relative;
      border: 1px solid color(spoiler-border);
      background-color: color(light-primary);
      &--is-open {
        .spoiler-button {
          &::before {
            content: ' ';
          }
        }
        .spoiler-arrow {
          &::before {
            top: 0.7rem;
            transform: rotate(225deg);
          }
        }
        .spoiler-content {
          visibility: visible;
          max-height: 130rem;
          transition: visibility 0s, max-height 0.4s ease-in, overflow 0s 0.4s;
        }
      }
      &-button {
        width: 100% !important; // overriding style: width coming from CMS
        padding: 0;
        color: color(dark-primary);
        font-size: 1.7rem;
        font-weight: 600;
        &::before {
          content: 'Read ';
          color: color(primary-text);
          font-weight: 400;
        }
      }
      &-arrow {
        width: 2.5rem;
        height: 2rem;
        position: absolute;
        top: 2rem;
        left: 1.2rem;
        cursor: pointer;
        &::before {
          content: '';
          display: block;
          width: 1.3rem;
          height: 1.3rem;
          position: absolute;
          top: 0;
          left: 0.6rem;
          transition: all 0.2s;
          transform: rotate(45deg);
          border: 1px solid color(dark-primary);
          border-width: 0 2px 2px 0;
        }
      }
      &-content {
        visibility: hidden;
        max-height: 0;
        overflow: hidden;
        transition: visibility 0s 0.4s, max-height 0.3s ease-out;
      }
      &-text {
        margin: 1.8rem 0 0;
      }
    }
    .table-container {
      overflow-x: scroll;
    }
    .modular-tile {
      &__header-row {
        margin: 0.4rem 0 0.8rem;
        padding-right: 3rem;
        @include min-lg {
          margin: 1rem 0 0.6rem;
        }
      }
      &__content {
        flex-direction: column;
      }
      &__main {
        display: flex;
        flex-direction: column;
        padding: 0 1rem 2.5rem;
        z-index: z-index(over-base);
        @include min-lg {
          padding: 0 3rem 3rem;
        }
      }
    }
    ul,
    ol {
      padding: 0 0 0 2rem;
    }
  }
</style>
