<template>
  <form-group
    :tag="'div'"
    v-bind="{
      descriptionId,
      label,
      info,
      name,
      testid,
      validation,
    }"
    v-slot="{ errors }"
  >
    <div
      class="textarea-group"
      :class="[
        { 'textarea-group--is-focused': isFocused },
        { 'textarea-group--is-required': isRequired && !hasHiddenAsterisk },
        { 'textarea-group--has-error': errors.length },
      ]"
      :data-testid="`${dataTestid} textarea-group`"
    >
      <div
        class="textarea-group__container"
        :class="[
          { 'nhsuk-form-group--error': errors.length }
        ]">
        <label
          v-if="showLabel"
          class="textarea-group__label"
          :class="{ 'textarea-group__label--is-active': isFocused || value }"
          data-testid="textarea-group-label"
          :for="id"
        >
          {{ labelText }}
        </label>
        <p
          v-if="showInfo"
          class="textarea-group__info">
          {{ info }}
        </p>
        <div
          class="textarea-group__errors"
          v-for="(error, i) in errors"
          :key="i"
          :id="descriptionId">
          <span class="nhsuk-error-message">
            <span class="nhsuk-u-visually-hidden">{{ i18n('(Error)') }}</span>
            {{ error }}
          </span>
        </div>
        <textarea
          ref="textarea"
          class="textarea-group__textarea"
          data-testid="textarea-group-textarea"
          :aria-describedby="descriptionId"
          :aria-invalid="!!errors.length"
          :aria-required="validation.required"
          :aria-label="showLabel ? undefined : ariaLabel"
          v-bind="{ maxLength, name, placeholder, rows, id }"
          v-model="model"
          @blur="toggleIsFocused"
          @focus="onFocus"
        />
      </div>
    </div>
  </form-group>
</template>

<script>
  import FormGroup from '@/components/forms/form-group/FormGroup'

  import i18n from '@/mixins/i18n'
  import labelText from '@/mixins/labelText'
  import testid from '@/mixins/testid/testid'

  export default {
    inheritAttrs: false,

    components: {
      FormGroup,
    },

    mixins: [
      labelText,
      testid(),
      i18n,
    ],

    props: {
      autoResize: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasHiddenAsterisk: {
        type: Boolean,
        default: false,
      },
      hideLabel: {
        type: Boolean,
        required: false,
        default: false,
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      info: {
        type: String,
        required: false,
        default: '',
      },
      name: {
        type: String,
        required: true,
      },
      nameAs: {
        type: String,
        required: false,
        default: '',
      },
      maxLength: {
        type: [Number, Boolean],
        required: false,
        default: false,
      },
      placeholder: {
        type: String,
        required: false,
        default: '',
      },
      rows: {
        type: Number,
        required: false,
        default: 3,
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      value: {
        type: String,
        required: false,
        default: '',
      },
    },

    data() {
      return {
        isFocused: false,
      }
    },

    methods: {
      toggleIsFocused() {
        this.isFocused = !this.isFocused
      },
      onFocus() {
        this.$emit('focus')
        this.toggleIsFocused()
      },
    },

    computed: {
      model: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
      descriptionId() {
        return `error-${this.name}_${this.uuid}`
      },
      ariaLabel() {
        return this.label || this.placeholder || false
      },
      showLabel() {
        return this.label && !this.hideLabel
      },
      showInfo() {
        return this.info
      },
      id() {
        return `${this.name}-${this.uuid}`
      },
    },
  }
</script>

<style lang="scss">
  .textarea-group {
    padding: 1.8rem 0 0 0;
    &--is-required {
      .textarea-group__label {
        &::after {
          content: '*';
        }
      }
    }
    &__container {
      position: relative;
    }
    &__label {
      display: inline-block;
      padding: 0 0 0.8rem 0;
      color: color(dark-primary);
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1;
      cursor: pointer;
    }
    &__info {
      padding: 0 0 0.8rem 0;
      font-size: 1.2rem;
    }
    &__textarea,
    &__input {
      width: 100%;
      padding: 1rem;
      color: color(dark-grey);
      vertical-align: top;
      resize: vertical;
      border: 1px solid color(input-border);
      border-radius: 0;
      background-color: color(_white);
      appearance: none;
      caret-color: color(input-border);
      &::placeholder {
        color: color(input-placeholder);
        opacity: 1;
      }
    }
    &__input {
      border-width: 0 0 1px 0;
      &:focus {
        border-width: 0 0 2px 0;
      }
    }
    &--has-error {
      // border-left: 3px solid color(error);
      .textarea-group__textarea {
        border-color: color(error);
      }
    }
  }
  .ios {
    .textarea-group {
      &__input,
      &__input::placeholder,
      &__textarea,
      &__textarea::placeholder {
        font-size: 1.6rem;
      }
    }
  }
</style>
