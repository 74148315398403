<script>
  import { mapGetters } from 'vuex'

  import { ContentNavigation } from '@/components/content-navigation'

  import testid from '@/mixins/testid/testid'

  export default {
    mixins: [
      testid('navigation'),
    ],

    props: {
      ariaLabel: {
        type: String,
        default: '',
      },
      id: {
        type: [Number, String],
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    render() {
      const previous = this.getItemOffset(this.itemId, -1)
      const next = this.getItemOffset(this.itemId, 1)
      const previousCategory = previous ? this.getCategoryByItemId(previous.id) : null
      const nextCategory = next ? this.getCategoryByItemId(next.id) : null

      return (
        <ContentNavigation
          ariaLabel={this.ariaLabel}
          class="reading-room-navigation"
          component='ReadingRoomArticle'
          isLoading={this.isLoading}
          next={next}
          nextCategory={nextCategory}
          previous={previous}
          previousCategory={previousCategory}
          testid={this.dataTestid}
        />
      )
    },

    computed: {
      ...mapGetters('readingRoom', [
        'getItemOffset',
        'getCategoryByItemId',
      ]),
      itemId() {
        return this.$route.params.id || this.id
      },
    },
  }
</script>
