<template>
  <base-main
    class="coaching-appointment-new"
    :loading="$options.loading"
  >
    <container
      :class="{ 'container--has-visible-calendar': isStepperContentVisible }"
      stepper
    >
      <stepper-custom-form
        ref="form"
        :class="{ 'stepper-form--overflow-visible': overflowVisible }"
        :buttonLabels="{ finishLabel: $t('Confirm') }"
        :isLoading="isFetchingCoachAvailability || isSaving"
        :isNextDisabled="isDisabled"
        @next="onNext"
        @previous="onPrevious"
        @cancel="onCancel"
        @submit="onSubmit"
      >
        <template #default>
          <stepper-custom-step
            class="step-date"
            :data-testid="`${testid}-stepper-custom-step`"
            :title="$t(`${$options.slug}.title-1`)"
          >
            <p>
                {{ $t(`${$options.slug}.description-1`) }}
              <router-link
                class="coaching-appointment-new__support"
                :data-testid="`${testid}-support-link`"
                :to="{ name: 'Support' }"
              >
                {{ $t(`${$options.slug}.contact-support`) }} </router-link>.
            </p>
            <div
              class="coaching-appointment-new-date"
              :class="{ 'coaching-appointment-new-date--has-date': form.date }"
            >
              <calendar-picker-group
                has-hidden-asterisk
                :format="'YYYY-MM-DD'"
                :formatted="DATE_FORMATS.date"
                :label="$t(`${$options.slug}.select-appointment`)"
                :maxDate="datepickerRange.to"
                :minDate="datepickerRange.from"
                :name="'date'"
                :outputFormat="'YYYY-MM-DD'"
                :type="'year'"
                :validation="$options.validation.date"
                v-bind="{ disabledDates, testid, availableDates }"
                v-model="form.date"
                @monthChanged="fetchCoachAvailableDaysWhenMonthChanged"
              />
            </div>
            <template
              v-if="hasDate"
            >
              <select-group
                :label="$t(`${$options.slug}.appointment-time`)"
                :name="'time'"
                :options="contactTimeOptions"
                :placeholder="$t(`${$options.slug}.select-time`)"
                :validation="$options.validation.time"
                v-bind="{ testid }"
                v-model="form.time"
                @change="onChangeTime"
              />
            </template>
          </stepper-custom-step>

          <stepper-custom-step
            class="step-message"
            :data-testid="`${testid}-stepper-custom-step`"
            :title="$t(`${$options.slug}.title-2`)"
          >
            <textarea-group
              :label="$t(`${$options.slug}.focus-label`)"
              :name="'focus'"
              :placeholder="$t(`${$options.slug}.focus-placeholder`)"
              :rows="6"
              :validation="$options.validation.message"
              v-bind="{ testid }"
              v-model="form.message"
            />
          </stepper-custom-step>

          <stepper-custom-step
            class="step-phone"
            :data-testid="`${testid}-stepper-custom-step`"
            :title="$t(`${$options.slug}.title-3`)"
          >
            <p>
              {{ $t(`${$options.slug}.description-2`) }}
            </p>
            <phones-group
              ref="phonesGroup"
              :isAnyRequired="false"
              :isOpen.sync="overflowVisible"
              :landline.sync="formUser.landline_phone"
              :mobile.sync="formUser.phone"
              :validationMobile="{
                ...$options.validation.mobile,
                'phone_number_error': phoneError,
                'required': !formUser.landline_phone
              }"
              :validationLandline="{
                ...$options.validation.landline, 'required': !formUser.phone
              }"
              v-bind="{ testid }"
            />
          </stepper-custom-step>

          <stepper-custom-step
            class="step-phone"
            :data-testid="`${testid}-stepper-custom-step`"
            :title="$t(`${$options.slug}.title-4`)"
          >
            <p
              :data-testid="`${testid}-confirm-text`"
              v-html="confirmAppointmentText"
            />
            <speech-bubble
              :imageLink="coachImage"
              v-bind="{ testid }"
            >
              {{ $t(`${$options.slug}.description-3`) }}
            </speech-bubble>
          </stepper-custom-step>
        </template>
      </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import { CalendarPickerGroup } from '@/components/forms/calendar-picker-group'
  import PhonesGroup from '@/components/forms/phones-group/PhonesGroup'
  import SelectGroup from '@/components/forms/select-group/SelectGroup'
  import TextareaGroup from '@/components/forms/textarea-group/TextareaGroup'
  import SpeechBubble from '@/components/speech-bubble/SpeechBubble'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import { DATE_FORMATS } from '@/constants'

  export default {
    metaInfo() {
      const state = this.id ? 'edit' : 'new'

      return {
        title: this.$t(
          `views.plugins.coaching-plugin.coaching-plugin-new-edit-appointment.${state}-appointment.meta.title`,
        ),
      }
    },

    components: {
      BaseMain,
      CalendarPickerGroup,
      Container,
      PhonesGroup,
      SelectGroup,
      SpeechBubble,
      StepperCustomForm,
      StepperCustomStep,
      TextareaGroup,
    },

    props: {
      id: {
        type: [Number, String],
        required: false,
        default: '',
      },
    },

    created() {
      if (this.id || this.preferences) return

      this.fetchPreferences(this.getCurrentUserId)
        .catch(() => (
          this.$router.replace({ name: 'CoachingPluginStart', params: { fromRedirect: true } })
        ))
    },

    watch: {
      /* eslint-disable-next-line object-shorthand */
      'form.date'(value) {
        if (!value) return

        this.getCoachAvailability()

        this.form.time = ''
      },
      /* eslint-disable-next-line object-shorthand */
      'formUser.phone'() {
        this.phoneError = false
      },
      preferences: {
        handler() {
          if (!this.preferences) return

          this.formUser.phone = this.getCurrentUserPhone
          /* eslint-disable-next-line camelcase */
          this.formUser.landline_phone = this.getCurrentUserLandlinePhone

          const getCoach = this.coach
                            ? () => false
                            : () => this.fetchCoach({
                                programId: this.currentProgramId,
                                userId: this.getCurrentUserId,
                              })

          const fetchCoachAvailableDays = () => (
            this.fetchCoachAvailableDays({
              date: this.getToday(),
              program_id: this.currentProgramId,
            })
          )

          if (this.id) {
            Promise.all([
              this.fetchAppointment(this.id),
              fetchCoachAvailableDays(),
              getCoach(),
            ])
              .then(([, dates]) => this.setAvailableDates(dates))
              .then(this.fillForm)
              .then(this.getCoachAvailability)
              .then(() => this.isLoadingFirstTime = false)
              .catch(err => {
                if (err) {
                  this.openDialog({ component: 'Error', props: { err } })
                }
                this.$router.push({ name: 'CoachingPlugin' })
              })
          } else {
            Promise.all([
              fetchCoachAvailableDays(),
              getCoach(),
            ])
              .then(([dates]) => this.setAvailableDates(dates))
              .then(() => this.isLoadingFirstTime = false)
              .catch(err => {
                if (err) {
                  this.openDialog({ component: 'Error', props: { err } })
                }
                this.$router.replace({ name: 'CoachingPlugin' })
              })
          }
        },
        immediate: true,
      },
    },

    data() {
      return {
        DATE_FORMATS,
        availableDates: [],
        form: {
          date: moment().format(DATE_FORMATS.date),
          time: '',
          message: '',
        },
        formUser: {
          phone: '',
          landline_phone: '',
          _method: 'PATCH',
        },
        text: this.$t(`${this.$options.slug}.looking-forward`),
        coachAvailability: null,
        isCountryListOpen: false,
        overflowVisible: false,
        disabledDates: [],
        datepickerRange: {
          from: '',
          to: '',
        },
        currentStep: 0,
        phoneError: false,
      }
    },

    methods: {
      ...mapActions('coaching', [
        'createAppointment',
        'editAppointment',
        'fetchAppointment',
        'fetchCoach',
        'fetchCoachAvailabilityForAppointment',
        'fetchCoachAvailability',
        'fetchCoachAvailableDays',
        'fetchPreferences',
      ]),
      ...mapActions('dialog', [
        'openDialog',
      ]),
      ...mapActions('user', [
        'updateUser',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      fillForm() {
        ['message'].forEach(key => {
          this.form[key] = this.appointment[key]
        })

        const [date] = this.appointment.since.split(' ')
        this.form.date = date
        this.form.time = this.getTimeRange(this.appointment.since, this.appointment.to)
      },
      onSubmit(isValid) {
        if (!isValid) return

        const action = this.id ? 'edit' : 'create'
        const form = this.getSubmitForm()
        const formData = new FormData()
        this.phoneError = false

        Object.entries(this.formUser)
          .filter(([, value]) => value)
          .forEach(([key, value]) => formData.append(key, value))

        this.updateUserProfileIfPhoneChanged(formData)
          .then(() => this[`${action}Appointment`]({ form, id: this.id }))
          .then(id => this.$router.push({ name: 'CoachingPluginShowAppointment', params: { id, fromNewEdit: true } }))
          .catch(err => {
            if (err) {
              const { errors } = err
              if (errors) {
                const { phone } = errors
                if (phone.length) {
                  this.phoneError = true
                  this.currentStep = 2
                  this.$refs.form.changeStep(2)
                  return
                }
              }
            }
            const message = this.getErrorMessage(err)

            this.addSnackbar({ message })
          })
      },
      updateUserProfileIfPhoneChanged(formData) {
        /* eslint-disable-next-line camelcase */
        return this.getCurrentUserPhone != this.formUser.phone
          || this.getCurrentUserLandlinePhone != this.formUser.landline_phone
           ? this.updateUser({ userId: this.getCurrentUserId, form: formData })
           : Promise.resolve()
      },
      getSubmitForm() {
        if (this.id) {
          return {
            message: this.form.message,
            since: this.dateTime.since,
          }
        }

        const { date, time, ...form } = this.form

        Object.assign(form, {
          ...this.dateTime,
          user_id: this.getCurrentUserId,
          coach_id: this.coach.id,
          program_id: this.currentProgramId,
        })

        return form
      },
      getToday() {
        return moment.utc().utcOffset(this.getTimezoneOffset).format('YYYY-MM-DD')
      },
      getCoachAvailability() {
        const form = {
          date: this.form.date,
          program_id: this.currentProgramId,
        }

        const fetchAvailability = this.id
          ? this.fetchCoachAvailabilityForAppointment
          : this.fetchCoachAvailability

        return fetchAvailability({
          form,
          appointmentId: this.id,
        })
          .then(coachAvailability => this.coachAvailability = coachAvailability)
      },
      getDisplayTime(date, mode) {
        const d = moment(date).add(this.getTimezoneOffset)
        return mode === 'add'
          ? d.add(this.coachAvailability.durations.pre, 'minutes')
          : d.subtract(this.coachAvailability.durations.post, 'minutes')
      },
      setAvailableDates(dates) {
        this.availableDates = dates?.[this.coach.id] || []
      },
      onCancel() {
        this.$router.replace({ name: 'CoachingPlugin' })
      },
      toggleOverflowVisible(flag) {
        this.overflowVisible = flag
      },
      getErrorMessage(error) {
        const { phone, landline_phone: landlinePhone } = error?.errors || {}
        let message = this.$t('Something went wrong')

        if (error) {
          message = phone || landlinePhone
            ? this.t(`${this.$options.slug}.wrong-number`)
            : error
        }

        return message
      },
      fetchCoachAvailableDaysWhenMonthChanged(date) {
        this.fetchCoachAvailableDays({
          date,
          program_id: this.currentProgramId,
        }).then(dates => {
          this.setAvailableDates(dates)
          this.setDisabledDates(date)
        })
        .catch(err => {
          if (err) {
            this.openDialog({ component: 'Error', props: { err } })
          }
          this.$router.push({ name: 'CoachingPlugin' })
        })
      },
      setDisabledDates(date) {
        const datepickerRange = () => ({
          from: date,
          to: moment(date).add(2, 'month').format('YYYY-MM-DD'),
        })
        this.datepickerRange = datepickerRange()
        const { from, to } = datepickerRange()
        const fromDate = moment(from).subtract(1, 'days')
        const toDate = moment(to)
        const dates = []

        while (fromDate.add(1, 'days').diff(toDate) <= 0) {
          dates.push(fromDate.clone().format('YYYY-MM-DD'))
        }

        this.disabledDates = dates.filter(time => !this.availableDates.includes(time))
      },
      onNext() {
        this.currentStep += 1
      },
      onPrevious() {
        this.currentStep -= 1
      },
      onChangeTime(value) {
        this.form.time = value
      },
    },

    computed: {
      ...mapGetters('coaching', [
        'coachImage',
        'getTimeRange',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
        'getCurrentUserLandlinePhone',
        'getCurrentUserPhone',
        'getTimezoneOffset',
      ]),
      ...mapState('coaching', [
        'appointment',
        'coach',
        'preferences',
      ]),
      ...mapState('layout', [
        'isStepperContentVisible',
      ]),
      confirmAppointmentText() {
        return this.$t(`${this.$options.slug}.confirm-appointment`, {
          date: moment(this.form.date).format(DATE_FORMATS.date),
          time: this.form.time,
        })
      },
      contactTimeOptions() {
        return this.coachAvailability?.availabilities?.[this.coach?.id]
          ?.map(({ from, to }) => this.getTimeRange(
              this.getDisplayTime(from, 'add'),
              this.getDisplayTime(to, 'sub'),
            ))
      },
      dateTime() {
        if (!this.form.time) return

        const format = 'YYYY-MM-DD HH:mm'
        const [startTime, endTime] = this.form.time.split('-')
        const since = moment(`${this.form.date} ${startTime}`, format)
            .subtract(this.coachAvailability.durations.pre, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss')
        const to = moment(`${this.form.date} ${endTime}`, format)
            .add(this.coachAvailability.durations.post, 'minutes')
            .format('YYYY-MM-DD H:mm:ss')

        return { since, to }
      },
      isDisabled() {
        if (this.currentStep === 2) {
          const phoneGroupRef = this.$refs.phonesGroup?.$refs

          // If the mobile phone has been updated
          // (i.e is not empty) and the validation
          // is not null, it means that an attempt to
          // validate has been made. If successful
          // then no need to disable.
          const isValidMobile = phoneGroupRef?.mobilePhoneFormGroup?.$refs.provider?.isValid
          if (isValidMobile !== null && this.formUser.phone !== '') {
            return !isValidMobile
          }

          // If the landline phone has been updated
          // (i.e is not empty) and the validation
          // is not null, it means that an attempt to
          // validate has been made. If successful
          // then no need to disable.
          const isValidLandline = phoneGroupRef?.landlinePhoneFormGroup?.$refs.provider?.isValid
          if (isValidLandline !== null && this.formUser.landline_phone !== '') {
            return !isValidLandline
          }

          // We need AT LEAST ONE of the phone numbers in order to book an appointment.
          if (this.formUser.phone === '' && this.formUser.landline_phone === '') {
            return true
          }

          return this.phoneError
        } else {
          if (!this.contactTimeOptions) return true

          return Boolean(!this.contactTimeOptions.length)
        }
      },
      isFetchingCoachAvailability() {
        return this.getLoadingStatesForActions([
          'coaching/fetchCoachAvailability',
          'coaching/fetchCoachAvailabilityForAppointment',
        ])
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'coaching/createAppointment',
          'coaching/editAppointment',
          'user/updateUser',
        ])
      },
      hasDate() {
        return !this.isDisabled && this.form.date
      },
      testid() {
        const action = this.id ? 'edit' : 'new'

        return `coaching-plugin-${action}-appointment`
      },
    },

    slug: 'views.plugins.coaching-plugin.coaching-plugin-new-edit-appointment',

    loading: [
      'coaching/fetchAppointment',
      'coaching/fetchCoach',
      'coaching/fetchCoachAvailability',
      'coaching/fetchCoachAvailabilityForAppointment',
      'coaching/fetchCoachAvailableDays',
      'coaching/fetchPreferences',
    ],

    validation: {
      date: {
        required: true,
      },
      landline: {
        phone_number: 'landline',
      },
      time: {
        required: true,
      },
      message: {
        required: true,
      },
      mobile: {
        phone_number: 'mobile',
      },
    },
  }
</script>

<style lang="scss">
  .coaching-appointment-new {
    .stepper-custom-progress-indicator {
      .progress-indicator {
        margin: 0 0 0 -2.4rem;
      }
    }
    &-date {
      &--has-date {
        .datepicker {
          .input-group-label {
            display: none;
          }
          span.input-group-icon {
            display: inline-block;
          }
        }
      }
      input {
        cursor: pointer;
      }
    }
    &-calendar {
      width: 2rem;
      margin: -1rem 0.8rem 0 0;
      fill: color(primary);
    }
    &__support {
      margin: 0;
      padding: 0;
      color: color(dark-primary);
      font-size: 1.4rem;
      font-weight: 600;
      text-decoration: underline;
    }
    .datepicker {
      display: block;
      width: 100%;
      .input-group {
        padding: 0;
        &-container {
          &:before {
            display: none;
          }
        }
        &-label {
          color: color(dark-primary);
          text-decoration: underline;
          &--is-active {
            transform: none;
          }
          &:after {
            display: none;
          }
        }
        &-input {
          width: auto;
          padding-bottom: 1.1rem;
          color: color(dark-primary);
          font-size: 1.6rem;
          font-weight: 600;
        }
        &-icon {
          display: none;
          margin-left: 0.7rem;
          padding-bottom: 0;
          transform: translateY(-0.8rem);
          @media all and (min-width: 400px) {
            margin-left: 1.5rem;
          }
        }
        .input-details {
          position: absolute;
          bottom: -1.3rem;
        }
      }
    }
    .step-message {
      .textarea-group {
        padding: 0;
      }
    }
    .stepper-buttons {
      margin-top: 3.5rem;
    }
    .phone-input-group {
      .vue-tel-input {
        .dropdown > ul {
          border-width: 1px;
        }
      }
    }
    .stepper-form.stepper-form--overflow-visible {
      .stepper-custom__content,
      .stepper-custom__steps,
      .stepper-custom-step {
        overflow: visible;
      }
    }
    .input-group__label,
    .select-group__label,
    .textarea-group__label,
    .phone-input-group-label {
      &::after {
        content: '';
      }
    }
    .speech-bubble {
      padding-top: 1.8rem;
    }
  }
</style>
