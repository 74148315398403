<script>
  import Tokenizer from 'shortcode-tokenizer'
  import Vue from 'vue'

  import { BaseArticleLink } from '@/components/base/base-article'
  import QuickQuestionEmbed from '@/components/quick-question-embed/QuickQuestionEmbed'
  import VideoArticleEmbed from '@/components/video-article-embed/VideoArticleEmbed'

  const hyphenate = key => key.replace(/([a-zA-Z])([A-Z])/g, '$1-$2').toLowerCase()

  /* map from code to component */
  const codeMap = {
    'article-link': BaseArticleLink,
    'video-article': VideoArticleEmbed,
    'quick-question': QuickQuestionEmbed,
  }

  const allComponents = Object.values(codeMap)
    .reduce((acc, c) => {
      acc[hyphenate(c.name)] = c
      return acc
    }, {})

  const renderToken = token => (
    wrapKeepAlive(
      renderText(
        renderSelfClosing(
          renderOpen(token),
        ),
      ),
    ).output
  )

  const wrapKeepAlive = token => {
    if (typeof token.params['keep-alive'] !== 'undefined') {
      token.output = `<keep-alive>${token.output}</keep-alive>`
    }
    return token
  }

  const renderParams = token => {
    if (Object.keys(token.params)) {
      /* eslint-disable-next-line prefer-template */
      return ' ' + Object.entries(token.params)
        .map(pair => {
          let [key, value] = pair

          if (key === 'keep-alive') {
            return null
          }
          key = `:${key}`
          if (typeof value === 'string') {
            value = `"${value}"`
            ;[key] = pair
          }
          return `${key}=${value}`
        })
        .join(' ')
    }
    return ''
  }

  const renderText = token => {
    if (token.type === Tokenizer.TEXT || token.type === Tokenizer.ERROR) {
      token.output = token.body
    }
    return token
  }

  const getComponentName = token => {
    if (typeof codeMap[token.name] === 'undefined') {
      return { name: token, isComponent: false }
    }
    return { name: hyphenate(codeMap[token.name].name), isComponent: true }
  }

  const renderOpen = token => {
    if (token.type === Tokenizer.OPEN) {
      const { name, isComponent } = getComponentName(token)
      const params = renderParams(token)
      const children = token.children
        .map(renderToken)
        .join('')
      token.output = isComponent
                      ? `<${name}${params}>${children}</${name}>`
                      : `[${name.name}${params}]${children}[/${name.name}]`
    }
    return token
  }

  const renderSelfClosing = token => {
    if (token.type === Tokenizer.SELF_CLOSING) {
      const { name, isComponent } = getComponentName(token)
      const params = renderParams(token)
      token.output = isComponent
                      ? `<${name}${params}></${name}>`
                      : name
    }
    return token
  }

  const ensureOneRoot = (source, content) => (
    source.length >= 1 ? `<div>${content}</div>` : content
  )

  export default {
    props: {
      content: {
        type: String,
        required: true,
      },
      strict: {
        type: Boolean,
        default: true,
      },
    },

    created() {
      this.tokenizer = new Tokenizer()
      this.tokenizer.options.strict = this.strict
    },

    mounted() {
      this.$emit('ready', true)
    },

    render(h) {
      return h(Vue.component('code-wrapper', {
        components: allComponents,
        template: this.renderContent(),
      }))
    },

    methods: {
      renderContent() {
        try {
          const ast = this.tokenizer
            .input(this.formatTable().innerHTML)
            .ast()
          const content = ast
            .map(renderToken)
            .join('')
          return ensureOneRoot(ast, content)
        } catch (err) {
          // TODO use error component
          return `<div class="error">${err.message}</div>`
        }
      },
      textToHtml(content) {
        // Otherwise, create div and append HTML
        const dom = document.createElement('div')
        dom.innerHTML = content
        return dom
      },
      formatTable() {
        const contentHtml = this.textToHtml(this.content)
        const tables = contentHtml.getElementsByTagName('table')

        tables.forEach(table => {
          const [firstTr, ...restTr] = table.getElementsByTagName('tr')
          restTr.forEach(tr => {
            tr.getElementsByTagName('td').forEach((td, index) => {
              const text = firstTr.children[index]?.innerText.replaceAll('\n', '') || ''
              const { width } = td.style
              td.style.setProperty('width', width, 'important')
              td.style.setProperty('min-width', '100%')
              if (text.length !== 1 && JSON.stringify(text) !== ' ') {
                td.setAttribute('attr-header', text)
              }
            })
          })
        })
        return contentHtml
      },
    },
  }
</script>
