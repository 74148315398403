<template>
  <form-group
    :tag="'div'"
    v-bind="{
      descriptionId,
      mode,
      name,
      testid,
      validation,
    }"
  >
    <calendar-picker
      has-form
      class="calendar-picker-group"
      :aria-describedby="descriptionId"
      v-bind="{
        buttonNowTranslation,
        disabled,
        disabledDates,
        disabledHours,
        disabledWeekly,
        firstDayOfWeek,
        format,
        formatted,
        hasHiddenAsterisk,
        inline,
        label,
        maxDate,
        minDate,
        minuteInterval,
        name,
        noButtonNow,
        noWeekendsDays,
        outputFormat,
        overlay,
        range,
        testid,
        validation,
        availableDates,
      }"
      v-model="model"
      @monthChanged="(date) => $emit('monthChanged', date)"
    />
  </form-group>
</template>

<script>
  import CalendarPicker from '@/components/forms/calendar-picker-group/CalendarPicker'
  import FormGroup from '@/components/forms/form-group/FormGroup'

  import { DATE_FORMATS } from '@/constants'

  import addVModel from '@/mixins/addVModel'
  import testid from '@/mixins/testid/testid'

  export default {
    inheritAttrs: false,

    components: {
      CalendarPicker,
      FormGroup,
    },

    mixins: [
      addVModel('model', String, ''),
      testid(),
    ],

    props: {
      label: {
        type: String,
        required: true,
      },
      mode: {
        type: [String, Function],
        default: 'aggressive',
      },
      name: {
        type: String,
        required: true,
      },
      preventFutureDate: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      hasHiddenAsterisk: {
        type: Boolean,
        default: false,
      },
      validation: {
        type: Object,
        default: () => ({}),
      },
      autoResize: {
        type: Boolean,
        required: false,
        default: false,
      },
      buttonNowTranslation: {
        type: String,
        required: false,
        default: 'Now',
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      disabledDates: {
        type: Array,
        required: false,
        default: () => [],
      },
      availableDates: {
        type: Array,
        required: false,
        default: () => [],
      },
      disabledHours: {
        type: Array,
        required: false,
        default: () => [],
      },
      disabledWeekly: {
        type: Array,
        required: false,
        default: () => [],
      },
      firstDayOfWeek: {
        type: Number,
        required: false,
        default: 1,
      },
      format: {
        type: String,
        required: false,
        default: DATE_FORMATS.dateTime,
      },
      formatted: {
        type: String,
        required: false,
        default: 'llll',
      },
      icon: {
        type: String,
        required: false,
        default: null,
      },
      inline: {
        type: Boolean,
        required: false,
        default: false,
      },
      maxDate: {
        type: String,
        required: false,
        default: null,
      },
      minDate: {
        type: String,
        required: false,
        default: null,
      },
      minuteInterval: {
        type: Number,
        required: false,
        default: 1,
      },
      noButtonNow: {
        type: Boolean,
        required: false,
        default: true,
      },
      noHeader: {
        type: Boolean,
        required: false,
        default: false,
      },
      noWeekendsDays: {
        type: Boolean,
        required: false,
        default: false,
      },
      outputFormat: {
        type: String,
        required: false,
        default: null,
      },
      overlay: {
        type: Boolean,
        required: false,
        default: false,
      },
      range: {
        type: Boolean,
        required: false,
        default: false,
      },
      tooltip: {
        type: String,
        required: false,
        default: null,
      },
    },

    computed: {
      descriptionId() {
        return `error-${this.name}_${this.uuid}`
      },
    },
  }
</script>
