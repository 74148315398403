var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',_vm._b({attrs:{"tag":'div'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"textarea-group",class:[
      { 'textarea-group--is-focused': _vm.isFocused },
      { 'textarea-group--is-required': _vm.isRequired && !_vm.hasHiddenAsterisk },
      { 'textarea-group--has-error': errors.length } ],attrs:{"data-testid":(_vm.dataTestid + " textarea-group")}},[_c('div',{staticClass:"textarea-group__container",class:[
        { 'nhsuk-form-group--error': errors.length }
      ]},[(_vm.showLabel)?_c('label',{staticClass:"textarea-group__label",class:{ 'textarea-group__label--is-active': _vm.isFocused || _vm.value },attrs:{"data-testid":"textarea-group-label","for":_vm.id}},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]):_vm._e(),(_vm.showInfo)?_c('p',{staticClass:"textarea-group__info"},[_vm._v(" "+_vm._s(_vm.info)+" ")]):_vm._e(),_vm._l((errors),function(error,i){return _c('div',{key:i,staticClass:"textarea-group__errors",attrs:{"id":_vm.descriptionId}},[_c('span',{staticClass:"nhsuk-error-message"},[_c('span',{staticClass:"nhsuk-u-visually-hidden"},[_vm._v(_vm._s(_vm.i18n('(Error)')))]),_vm._v(" "+_vm._s(error)+" ")])])}),_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"textarea",staticClass:"textarea-group__textarea",attrs:{"data-testid":"textarea-group-textarea","aria-describedby":_vm.descriptionId,"aria-invalid":!!errors.length,"aria-required":_vm.validation.required,"aria-label":_vm.showLabel ? undefined : _vm.ariaLabel},domProps:{"value":(_vm.model)},on:{"blur":_vm.toggleIsFocused,"focus":_vm.onFocus,"input":function($event){if($event.target.composing){ return; }_vm.model=$event.target.value}}},'textarea',{ maxLength: _vm.maxLength, name: _vm.name, placeholder: _vm.placeholder, rows: _vm.rows, id: _vm.id },false))],2)])]}}])},'form-group',{
    descriptionId: _vm.descriptionId,
    label: _vm.label,
    info: _vm.info,
    name: _vm.name,
    testid: _vm.testid,
    validation: _vm.validation,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }