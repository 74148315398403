<template>
  <div
    class="goals-form"
    data-testid="goals-form"
    v-merge-attr:data-testid
  >
    <stepper-custom-form
      v-if="showForm"
      data-testid="goals-form-stepper"
      :isLoading="isSaving"
      @cancel="goBack"
      @submit="onSubmit"
    >
      <stepper-custom-step
        v-for="({ title, ...action }, i) in stepperActions"
        :key="i"
        data-testid="goals-form-step"
        v-bind="{ title }"
      >
        <goals-form-step
          :showWeight="isWeightGoal && i === 0"
          :extraDescription="i === 0 ? profile.extraDescription : ''"
          v-bind="action"
          @add-answer="addAnswerField(i)"
          @remove-field="removeAnswerField(i, $event)"
        />
      </stepper-custom-step>
    </stepper-custom-form>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters, mapState } from 'vuex'

  import GoalsFormStep from '@/components/goals/GoalsFormStep'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import mergeAttr from '@/directives/mergeAttr'

  import goBack from '@/mixins/goBack'
  import weightUnitConverter from '@/mixins/weight-unit-converter/weightUnitConverter'

  export default {
    components: {
      GoalsFormStep,
      StepperCustomForm,
      StepperCustomStep,
    },

    directives: {
      mergeAttr,
    },

    mixins: [
      goBack,
      weightUnitConverter,
    ],

    props: {
      isSaving: {
        type: Boolean,
        default: false,
      },
      isEditMode: {
        type: Boolean,
        default: false,
      },
      profileId: {
        type: Number,
        required: true,
      },
    },

    methods: {
      onSubmit(isValid) {
        this.$emit('submit', isValid, this.stepperActions)
      },
      getActionField({ title, description, icon, ...action }, index) {
        const firstAction = index === 0
        const inputType = firstAction
          ? this.profile.input_type
          : 'text'
        const inputMeta = firstAction
          ? this.profile.input_meta
          : null

        const answerId = action.allow_multiple && this.isEditMode
          ? (this.goal.answers[index]?.content.length || 1) - 1
          : 0

        const { isTrackingGoal } = this

        return {
          allowMultiple: !!action.allow_multiple,
          token: action.answer_token,
          answers: Vue.observable({}),
          actionFields: this.generateFields(index, inputMeta, inputType),
          answerId,
          title,
          description,
          chMessage: action.message,
          imageUrl: this.getSpeechBubbleImageUrl(icon),
          isTrackingGoal,
        }
      },
      generateFields(actionIndex, inputMeta, inputType) {
        // eslint-disable-next-line camelcase
        if (this.isStonesAndPounds && this.isWeightGoal && inputMeta?.dynamic_label === 'user.distance_unit.weight') {
          let defaultValue = { stones: null, pounds: null }
          if (this.isEditMode) {
            const answer = this.goal.answers[actionIndex]?.content[0]
            defaultValue = this.convertToStonesOrPoundsIfNeeded(answer)
          }
          return Vue.observable([
            { ...this.getAnswerField({
              actionIndex, meta: { ...inputMeta, hiddenRemoveField: true }, required: true, inputType, title: 'Stones', step: 1,
            }),
              default: defaultValue.stones },
            { ...this.getAnswerField({
              actionIndex, fieldIndex: 1, meta: { ...inputMeta, max: 14, hiddenRemoveField: true }, required: true, inputType, title: 'Pounds', step: 1,
            }),
              default: defaultValue.pounds },
          ])
        }
        if (!this.isEditMode || !this.goal.answers[actionIndex]) {
          return Vue.observable([
            this.getAnswerField({
              actionIndex, meta: inputMeta, required: true, inputType,
            }),
          ])
        }

        const fields = this.goal.answers[actionIndex]?.content.map((answer, fieldIndex) => {
          const field = this.getAnswerField({
            actionIndex,
            fieldIndex,
            ...this.getFieldParameters({ actionIndex, fieldIndex, inputMeta, inputType }),
          })

          const firstActionAndField = actionIndex === 0 && fieldIndex === 0
          field.default = firstActionAndField && this.isWeightGoal
            ? this.convertToStonesOrPoundsIfNeeded(answer).value
            : answer

          return field
        })

        return Vue.observable(fields)
      },
      getFieldParameters({ actionIndex, fieldIndex, inputMeta, inputType }) {
        let meta = {}
        let type = 'text'
        const firstActionAndField = actionIndex === 0 && fieldIndex === 0

        if (firstActionAndField) meta = inputMeta
        if (firstActionAndField) type = inputType

        return {
          meta,
          required: fieldIndex === 0,
          inputType: type,
        }
      },
      getAnswerField({ actionIndex, fieldIndex = 0, meta = {}, required = false, inputType = 'text', title = '' }) {
        const type = inputType === 'number'
          ? 'InputGroup'
          : 'TextareaGroup'
        const validation = { required }
        const copyOfMeta = { ...meta }
        const { title: label } = this.profile.actions[actionIndex]

        if (meta?.min !== undefined && meta?.max !== undefined) {
          if (!copyOfMeta.step) {
            copyOfMeta.step = 0.01
            validation.decimal = 2
          }
          if (!copyOfMeta.max) {
            copyOfMeta.max = this.convertMetaValueIfNeeded(copyOfMeta, 'max')
          }
          if (!copyOfMeta.min) {
            copyOfMeta.min = this.convertMetaValueIfNeeded(copyOfMeta, 'min')
          }

          validation.min_value = copyOfMeta.min
          validation.max_value = copyOfMeta.max
        }

        const { dynamic_label, ...metaWithoutDynamicLabel } = copyOfMeta

        return {
          type,
          name: `action_${actionIndex}_${fieldIndex}`,
          props: {
            label: title || label,
            validation,
            hasHiddenAsterisk: true,
            type: inputType,
            ...metaWithoutDynamicLabel,
          },
        }
      },
      convertMetaValueIfNeeded(meta, value) {
        const { dynamic_label: dynamicLabel } = meta || {}
        const shouldConvert = dynamicLabel === 'user.distance_unit.weight'

        if (!shouldConvert) return meta[value]

        if (this.isStonesAndPounds) {
          return this.convertToStonesOrPoundsIfNeeded(meta[value]).stones || 0
        }

        return this.convertToStonesOrPoundsIfNeeded(meta[value]).value
      },
      addAnswerField(actionIndex) {
        this.stepperActions[actionIndex].answerId += 1
        const { actionFields, answerId } = this.stepperActions[actionIndex]

        actionFields.push(
          this.getAnswerField({ actionIndex, fieldIndex: answerId }),
        )
      },
      removeAnswerField(actionIndex, answerIndex) {
        const action = this.stepperActions[actionIndex]
        const { name } = action.actionFields[answerIndex]

        Vue.delete(action.answers, name)
        action.actionFields.splice(answerIndex, 1)
      },
      getSpeechBubbleImageUrl(icon) {
        return this.profile.profile_icon?.url
          ? this.profile.profile_icon.url
          : icon?.url || null
      },
    },

    computed: {
      ...mapGetters('user', [
        'isStonesAndPounds',
        'getCurrentUserWeightUnit',
      ]),
      ...mapGetters('goals', [
        'getProfileById',
      ]),
      ...mapState('goals', [
        'goal',
        'plugin',
      ]),
      showForm() {
        return this.stepperActions.length > 0
      },
      stepperActions() {
        return this.profile?.actions?.map(this.getActionField) || []
      },
      isWeightGoal() {
        /* eslint-disable-next-line camelcase */
        return this.profile?.template_slug === 'weight_goal'
      },
      profile() {
        return this.plugin && this.getProfileById(this.profileId)
      },
      isTrackingGoal() {
        /* eslint-disable-next-line camelcase */
        return this.profile?.template_slug != null
      },
    },
  }
</script>

<style lang="scss">
  .goals-form {
    @include view-wrapper(63rem);
    &__description {
      font-size: $font-size-p-sm;
      @include min-lg {
        font-size: $font-size-p-lg;
      }
    }
  }
</style>
