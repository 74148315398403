<template>
  <div
    class="article-video"
    data-testid="base-article-video"
  >
    <modular-tile
      :headerText="article.title"
      :headerLevel="1"
    >
      <video-player
        class="article-video-player"
        ref="player"
        data-testid="base-article-video-player"
        :item="article"
        v-bind="{ captions, alternateCaptions }"
        @player="setPlayer"
      />
      <journey-navigation
        v-if="isJourney"
        :testid="'base-article-video'"
        :key="isLoading"
        :articleId="id"
        :canProceed="!isLoading"
        v-bind="{
          isLoading,
        }"
      />
      <reading-room-navigation
        v-else-if="isReadingRoom"
        :ariaLabel="$t('Content')"
        :categoryId="$route.params.categoryId"
        :id="$route.params.id"
        v-bind="{
          isLoading,
        }"
      />
    </modular-tile>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import { JourneyNavigation } from '@/components/journey'
  import { ReadingRoomNavigation } from '@/components/reading-room'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'
  import VideoPlayer from '@/components/video-player/VideoPlayer'

  export default {
    inheritAttrs: false,

    components: {
      JourneyNavigation,
      ModularTile,
      ReadingRoomNavigation,
      VideoPlayer,
    },

    props: {
      article: {
        type: Object,
        required: true,
      },
      id: {
        type: [String, Number],
        required: true,
      },
      isJourney: {
        type: Boolean,
        default: false,
      },
      isReadingRoom: {
        type: Boolean,
        default: false,
      },
    },

    mounted() {
      const { id, language } = this.article

      this.fetchVideoCaptions({
        id,
        language,
      })
      .then(captionsUrl => this.captions = captionsUrl)

      this.fetchVideoCaptions({
        id,
        language,
        isAlternateCaptions: true,
      })
      .then(captionsUrl => this.alternateCaptions = captionsUrl)
    },

    data() {
      return {
        player: null,
        captions: '',
        alternateCaptions: '',
      }
    },

    methods: {
      ...mapActions('article', [
        'fetchVideoCaptions',
      ]),
      setPlayer(player) {
        this.player = player

        if (player) this.passDuration()
      },
      passDuration() {
        if (this.player.readyState() < 1) {
          this.player.one('loadedmetadata', () => this.emitDuration(this.player?.duration))
        } else {
          this.emitDuration(this.player?.duration)
        }
      },
      emitDuration(duration) {
        this.$emit('duration', duration)
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isLoading() {
        return this.getLoadingStatesForActions([
          'article/fetchArticle',
          'article/fetchVideoCaptions',
          'program/fetchNextSection',
          'readingRoom/fetchItem',
        ])
      },
    },
  }
</script>

<style lang="scss">
  $playIcon: '~@/assets/svg/play.svg';

  .article-video {
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include min-lg {
      padding-top: 3rem;
    }
    &-player {
      width: 100%;
      .video-js {
        width: 100%;
        height: 0;
        padding-top: 56.25%;
        position: relative;
        overflow: hidden;
        border-radius: 1rem;
        &:hover .vjs-big-play-button,
        .vjs-big-play-button {
          width: 5.6rem;
          height: 5.6rem;
        }
        .vjs-big-play-button {
          border-color: color(primary);
          background-color: color(primary);
          .vjs-icon-placeholder::before {
            content: '';
            left: 0.2rem;
            background-image: url($playIcon);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 1.7rem 2.2rem;
          }
        }
      }
    }
    .modular-tile {
      flex-grow: 0;
      &__header-row {
        margin: 0.4rem 0 0.8rem;
        @include min-lg {
          margin: 1rem 0 0.6rem;
        }
      }
      &__content {
        flex-direction: column;
      }
      &__main {
        @include min-lg {
          padding: 0 3rem 3rem;
        }
      }
    }
  }
</style>
