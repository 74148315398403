<script>
  export default {
    name: 'BaseArticleLink',

    functional: true,

    props: {
      external: {
        type: String,
        required: false,
        default: '',
      },
      plugin: {
        type: String,
        required: false,
        default: '',
      },
    },

    render(h, { children, props }) {
      let link

      /* eslint-disable vue/script-indent */
      if (props.external) {
        link = h('a', {
          staticClass: 'article-link',
          domProps: {
            href: props.external,
            target: '_blank',
          },
        }, children.map(node => node.text += ' (opens in a new window)'))
      } else if (props.plugin) {
        const routes = {
          'activity-quiz': 'ActivityQuiz',
          'capturing-bmi': 'CapturingBMI',
          'carb-calculator': 'CarbCalculator',
          coaching: 'CoachingPlugin',
          'coaching-plugin': 'CoachingPlugin',
          'face-age-test': 'FaceAgeTest',
          'food-tracking': 'FoodTracking',
          'getting-confident-quiz': 'GettingConfident',
          'getting-support-quiz': 'GettingSupport',
          goal: 'GoalsView',
          'goal-plugin': 'GoalsView',
          goals: 'GoalsView',
          'maintenance-plan': 'MaintenancePlan',
          'reading-room': 'ReadingRoom',
          'reflecting-quiz': 'Reflecting',
          'reasons-to-lose-weight': 'ReasonsToLoseWeight',
          'risk-calculator': 'RiskCalculator',
          'setting-the-strongest-plan': 'SettingTheStrongestPlan',
          'shopping-list-switcher': 'ShoppingListSwitcher',
          'step-tracking': 'StepTracking',
          'tricky-situations-quiz': 'TrickySituations',
          'weight-tracking': 'WeightTracking',
          'winning-against-temptations': 'Temptations',
          'blood-glucose-tracking': 'GlucoseTracking',
        }

        link = h('router-link', {
          staticClass: 'article-link',
          props: {
            to: { name: routes[props.plugin] },
          },
        }, children)
      }
      /* eslint-enable */
      return link
    },
  }
</script>

<style lang="scss">
  .article-link {
    display: inline-block;
    color: color(primary);
    text-decoration: underline;
  }
</style>
