<template>
  <div
    v-if="question"
  >
    <div
      v-if="!isLoading"
      class="quick-question-embed">
      <p v-if="!isSolved">
        {{ question.question }}
      </p>
      <div
        class="answer-container"
        v-if="!isSolved">
        <button
          v-for="(answer, index) in question.answers"
          :data-testid="answer.id"
          :key="answer.id"
          :class="{
            'quick-question-embed__answer': true,
            'selected': isSelected(answer.id),
          }"
          @click="selectAnswer(answer)">
          <div class="container-answer__letter">
            <p class="answer__letter">
              {{ answerLetter(index) }}
            </p>
            <p>{{ answer.content }}</p>
          </div>
          <inline-svg
            class="check__icon"
            role="presentation"
            src="icons/icon-check.svg"
          />
        </button>
      </div>
      <button
        v-if="!isSolved"
        class="check-answer-button"
        data-testid="check-answer-button"
        :disabled="!selectedAnswer"
        @click="sendYourAnswer()">
        Check your answer
      </button>
      <div
        v-if="isSolved"
        class="nhsuk-u-margin-left-3">
        <p class="option__text nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-3">
          You chose:
          <span
            v-for="(a, index) in selectedAnswer"
            :key="a.id">
            {{ a.content }}{{ (index !== selectedAnswer.length - 1) ? ', ' : '' }}
          </span>
        </p>
        <p class="response__text nhsuk-u-margin-bottom-3">
          {{ response }}
        </p>
        <button
          class="check-answer-button"
          data-testid="check-answer-button"
          @click="repeatQuestion()">
          Repeat the question
        </button>
      </div>
    </div>
    <div
      v-else
      class="quick-question-loading">
      <spinner />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import Spinner from '@/components/spinner/Spinner'

  export default {
    name: 'QuickQuestionEmbed',

    components: {
      Spinner,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    watch: {
      question: {
        handler() {
          this.getResponseQuestion(this.question.id).then(answersResponse => {
            if (answersResponse.length === 0) {
              return
            }
            const answers = this.question.answers.filter(({ id }) => answersResponse.includes(id))
            this.selectedAnswer = answers
            this.savedResponse = true
            this.checkYourAnswer()
          })
        },
      },
    },

    data() {
      return {
        selectedAnswer: [],
        response: '',
        isSolved: false,
        correctResponse: false,
        savedResponse: false,
      }
    },

    methods: {
      ...mapActions('quickQuestions', [
        'saveResponseQuestion',
        'updateResponseQuestion',
        'getResponseQuestion',
      ]),
      answerLetter(index) {
        return ((index + 1) + 9).toString(36).toUpperCase()
      },
      selectAnswer(answer) {
        if (this.isSelected(answer.id)) {
          this.selectedAnswer = this.selectedAnswer.filter(({ id }) => id !== answer.id)
        } else {
          this.selectedAnswer.push(answer)
        }
      },
      checkYourAnswer() {
        const { question } = this
        const rightAnswers = question.answers
          // eslint-disable-next-line camelcase
          .filter(({ is_right }) => !!is_right)
        let correct = rightAnswers.length === this.selectedAnswer.length

        if (!correct) {
          const selectedAnswersId = this.selectedAnswer.map(({ id }) => id)
          rightAnswers.forEach(answer => {
            if (!selectedAnswersId.includes(answer.id)) {
              correct = false
            }
          })
        }

        if (correct) {
          this.response = question.right_answer_response
          this.correctResponse = true
        } else {
          this.response = question.wrong_answer_response
        }

        this.isSolved = true
      },
      sendYourAnswer() {
        this.checkYourAnswer()
        if (this.savedResponse) {
          this.updateResponseQuestion({
            questionId: this.question.id,
            answers: this.selectedAnswer,
          })
        } else {
          this.saveResponseQuestion({ questionId: this.question.id, answers: this.selectedAnswer })
        }
      },
      isSelected(answerId) {
        return this.selectedAnswer.find(({ id }) => id === answerId)
      },
      repeatQuestion() {
        this.isSolved = false
        this.selectedAnswer = []
      },
    },

    computed: {
      ...mapGetters('quickQuestions', [
        'getQuestion',
        'getQuestions',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      question() {
        return this.getQuestion(this.id)
      },
      isLoading() {
        return this.getLoadingStatesForActions([
          'quickQuestions/getResponseQuestion',
        ])
      },
    },
  }
</script>

<style lang="scss">

$blueColor: rgb(28, 68, 169);

.quick-question-loading {
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    $_size: 3.2rem;
    width: $_size;
    height: $_size;
  }
}

.quick-question-embed {
  padding-left: 2rem;

  .answer-container {
    min-width: 200px;
    width: fit-content;
  }

  .check-answer-button {
    background-color: $blueColor;
    color: color(_white);
    border-radius: 4px;
    padding: 1rem 2rem;
  }

  .option__text {
    // margin-bottom: 0 !important;

    span {
      font-weight: bold;
    }
  }

  .response__text {
    margin-top: 0 !important;
  }

  &__answer {
    background-color: rgba(4, 69, 175, 0.1);
    padding: 0.25rem 1rem;
    color: $blueColor;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
    box-shadow: rgba(4, 69, 175, 0.6) 0px 0px 0px 1px inset;

    .container-answer__letter {
      display: flex;
      align-items: center;
    }

    .check__icon {
      margin-left: 0.5rem;
      visibility: hidden;
      width: 30px;
    }

    &.selected {
      box-shadow: rgba(4, 69, 175, 0.8) 0px 0px 0px 2px inset;

      .check__icon {
        fill: $blueColor;
        visibility: visible;

        svg {
          width: 22px;
          height: 22px;
        }
      }

      .answer__letter {
        background-color: $blueColor;
        color: color(_white);
      }
    }

    p.answer__letter {
      width: 24px;
      min-width: 22px;
      height: 24px;
      border-radius: 2px;
      font-size: 12px !important;
      font-weight: 600;
      line-height: 16px;
      border: solid 1px $blueColor;
      background-color: color(_white);
      color: $blueColor;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem !important;
    }
  }
}

</style>
