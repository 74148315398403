var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article"},[(_vm.hasFavouriteButton)?_c('button',{staticClass:"article__favourite",attrs:{"aria-label":"Add article to favourites","data-testid":(_vm.dataTestid + "-favourite"),"type":"button"},on:{"click":_vm.onFavoriteClick}},[_c('reading-room-icon',{attrs:{"type":_vm.favouriteIcon}})],1):_vm._e(),(_vm.isArticle)?_c(_vm.articleComponent,_vm._b({tag:"component",on:{"duration":_vm.onDuration,"submit":_vm.onSubmit}},'component',{
      article: _vm.article,
      content: _vm.content,
      id: _vm.id,
      isCompleted: _vm.isCompleted,
      isJourney: _vm.isJourney,
      isReadingRoom: _vm.isReadingRoom,
    },false)):_c('item-component',_vm._b({attrs:{"is-reading-room":"","testid":_vm.dataTestid}},'item-component',{ item: _vm.item },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }