<template>
  <div
    class="sliding-scale"
    data-testid="sliding-scale"
  >
    <div
      class="sliding-scale__ticks"
      data-testid="sliding-scale-ticks"
    >
      <span
        ref="scaleTick"
        v-for="(label, i) in tickNumbers"
        :key="i"
        aria-hidden="true"
        class="sliding-scale__tick"
        :style="{ width: `${tickWidth}px` }"
      >
        {{ label }}
      </span>
    </div>
    <div
      class="sliding-scale__slider"
      data-testid="sliding-scale-slider"
    >
      <vue-slider
        ref="slider"
        :adsorb="false"
        :dotSize="20"
        :duration="0.3"
        :height="14"
        tooltip="none"
        v-bind="{ max, min }"
        v-model="rangeValue"
      />
    </div>

    <div
      class="sliding-scale__context-labels"
      data-testid="sliding-scale-context-labels"
    >
      <span
        v-for="(label, i) in contextLabels"
        :key="i"
        class="sliding-scale__context-label"
        data-testid="sliding-scale-context-label"
      >
        {{ $t(label) }}
      </span>
    </div>
  </div>
</template>
<script>
  import VueSlider from 'vue-slider-component'

  import i18n from '@/mixins/i18n'

  export default {
    components: {
      VueSlider,
    },

    mixins: [
      i18n,
    ],

    props: {
      ariaLabel: {
        type: String,
        required: false,
        default: '',
      },
      showTicks: {
        type: Boolean,
        required: false,
        default: true,
      },
      contextLabels: {
        type: Array,
        default: () => [],
      },
      max: {
        type: Number,
        required: true,
      },
      min: {
        type: Number,
        default: 0,
      },
      interval: {
        type: Number,
        default: 1,
      },
      name: {
        type: String,
        default: '',
      },
      value: {
        type: Number,
        required: true,
      },
    },

    mounted() {
      this.tickWidth = this.getTickMaxWidth()
      this.updateAriaLabel()
    },

    beforeDestroy() {
      clearTimeout(this.resizeTimeout)
    },

    watch: {
      value(val) {
        this.$announcer.set(val)
      },

      ariaLabel: {
        handler() {
          this.updateAriaLabel()
        },
        immediate: true,
      },
    },

    data() {
      return {
        tickWidth: 'auto',
        resizeTimeout: null,
      }
    },

    methods: {
      getTickMaxWidth() {
        return this.$refs.scaleTick.reduce((acc, curr) => (
          curr.offsetWidth > acc
            ? curr.offsetWidth
            : acc
        ), 0)
      },
      updateAriaLabel() {
        const translatedLabels = this.contextLabels.map(label => this.$t(label)).join(', ')
        const defaultLabel = translatedLabels ? `Slider with: ${translatedLabels}` : 'Slider'
        const fullLabel = `${this.ariaLabel} ${defaultLabel}`.trim()

        return this.$refs.slider?.$el.setAttribute('aria-label', fullLabel)
      },
    },

    computed: {
      rangeValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', +value)
        },
      },
      tickNumbers() {
        const numbers = []

        for (let i = this.min; i < this.max + 1; i += this.interval) {
          numbers.push(i)
        }

        return numbers
      },
    },

    slug: 'component.forms.sliding-scale',
  }
</script>
<style lang="scss">
  .sliding-scale {
    &__ticks {
      display: flex;
      justify-content: space-between;
      padding: 4.8rem 3.2rem 1rem 3.2rem;
    }
    &__tick {
      display: flex;
      justify-content: center;
      color: color(primary);
      font-weight: 800;
    }
    &__slider {
      padding: 2.5rem 3.2rem;
      border-radius: 10rem;
      background-color: color(_white);
      box-shadow: box-shadow(default);
    }
    &__context-labels {
      display: flex;
      justify-content: space-between;
      padding: 1.6rem 2.8rem;
    }
    &__context-label {
      color: color(grey);
      font-size: 1.6rem;
    }
  }
  .vue-slider {
    &-rail {
      cursor: pointer;
      border-radius: 1rem;
      background-color: color(light-grey);
    }
    &-process {
      border-radius: 1rem 0 0 1rem;
      background-color: color(primary);
    }
    &-dot {
      .vue-slider-dot-handle {
        height: 100%;
        position: relative;
        box-sizing: content-box;
        border-radius: 50%;
        background: color(_transparent);
        box-shadow: none;
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.575);
          border-radius: 50%;
          background-color: color(dark-primary);
        }
        &::after {
          content: '';
          width: 80%;
          height: 80%;
          position: absolute;
          top: 50%;
          left: 50%;
          transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.575);
          transform: translate(-50%, -50%) scale(0);
          border-radius: 50%;
          background-color: color(primary);
        }
      }
    }
    &-marks {
      bottom: 9rem;
    }
    &-mark {
      color: color(primary);
      font-weight: 800;
    }
    &-ltr &-mark-label {
      margin-top: -2rem;
      padding: 2rem;
      top: 0;
    }
    &-marks {
      bottom: 7rem;
    }
    &.vue-slider-state-drag .vue-slider-dot-handle {
      &::before {
        transform: scale(1.6);
      }
      &::after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
    & .vue-slider-sr-only {
      display: none;
    }
  }
</style>
