<template>
  <div
    class="article-video"
    data-testid="base-article-video"
  >
    <div class="container-green-points">
      <span
        v-for="dietSuitability in dietSuitabilities"
        class="point green-point"
        :key="dietSuitability">
        {{ dietSuitability }}
      </span>
    </div>
    <span class="point red-point">
      Contains {{ recipe.allergenInformation }}
    </span>
    <div class="container-circles">

      <div class="container-circle">
        <span>Ready in</span>
        <div class="circle">
          <span>{{ recipe.minutesReady }}</span>
          <span>minutes</span>
        </div>
      </div>
      <div class="container-circle">
        <span>Prep time</span>
        <div class="circle">
          <span>{{ recipe.minutesPrep }}</span>
          <span>minutes</span>
        </div>
      </div>
      <div class="container-circle">
        <span>Cooking time</span>
        <div class="circle">
          <span>{{ recipe.minutesCooking }}</span>
          <span>minutes</span>
        </div>
      </div>
      <div class="container-circle">
        <span>Serves</span>
        <div class="circle">
          <span>{{ recipe.peopleServes }}</span>
          <span>people</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    inheritAttrs: false,

    props: {
      recipe: {
        type: Object,
        required: true,
      },
    },

    computed: {
      dietSuitabilities() {
        return this.recipe.dietSuitability.split(',')
      },
    },
  }
</script>

<style lang="scss">
.point {
  display: flex;
  align-items: center;
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 0.5rem;
  }
}
.container-green-points {
  display: flex;
  flex-direction: column;

  .green-point {
    &::before {
      background: color(food-green);
    }
  }
}

.red-point {
  color: color(food-red);
  &::before {
    background: color(food-red);
  }
}
.container-circles {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-items: flex-end;
  .container-circle {
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    width: 100px;
    font-weight: 600;

    > span {
      margin-bottom: 0.5rem;
      text-align: center;
    }

    .circle {
      color: color(_white);
      text-align: center;
      border-radius: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      width: 70px;
      height: 70px;
      span:first-child {
        font-size: x-large;
        line-height: 25px;
        font-weight: bold;
      }
      span:last-child {
        line-height: 16px;
      }
    }
    &:first-child {
      .circle {
        background: #33339d;
      }
    }
    &:nth-child(2) {
      .circle {
        background: #70bede;
      }
    }
    &:nth-child(3) {
      .circle {
        background: color(food-amber);
      }
    }
    &:last-child {
      .circle {
        background: color(food-red);
      }
    }
  }
}
</style>
